export default {
    confirm_dob : 'Please confirm you are over 18 years old',
    is_valid_password : "Please provide valid password that is at least 8 characters, upper and lowercase letters, 1 number and 1 special character",
    is_required_password : "Password is required!",
    is_valid_number : "Invalid phone Number!",
    is_required_number : "Phone number is required!",
    is_required_state : "State is required!",
    is_cuisine_required : "Select Cuisine",
    is_required_time : "Time is required!",
    is_valid_file_format : "Invalid file format!",
    file_size : "File size must be less than 10 mb!",
    accept_files : "file must have a .jpeg/.jpg/.png/.pdf extensions",
    required_doc : "Business registration certificate is required",
    leave_page : "Are you sure you want to leave this page ? All the unsaved data will be lost.",
    same_password : "New Password and Current Password Can't be same",
    search_by : "Search by Postcode or BYO's name"
}