import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
// import Slider from "react-slick";
import Layout from '../../layout';
import './byo.scss';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Helmet from 'react-helmet';
const queryString = require('query-string');
export default class ClaimByoDetail extends Component {
    state = {
        byo_detail: "",
        byo_images: [],
        next_page: "",
    }

    componentDidMount() {
        this.getByoDetail();
        this.getImages();
        (function () {
            window.scrollTo(0, 0)
        })()
    }

    getByoDetail() {
        this.props.axios.get(`byo-claim/${this.props.match.params.id}/`).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ byo_detail: res.data.data })
            }
        })
    }

    getImages() {
        this.props.axios.get(`byo-image/`, { params: { b_y_o_id: this.props.match.params.id } }).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ byo_images: res.data.data, next_page: res.data.next })
            }
        })
    }

    handleMoreImages = () => {
        const parsed = queryString.parseUrl(this.state.next_page);
        this.props.axios.get(`byo-image/`, { params: parsed.query }).then(res => {
            if (res.data.status.code === 200) {
                this.setState({ byo_images: [...this.state.byo_images, ...res.data.data], next_page: res.data.next })
            }
        })
    }


    render() {
        // var settings = {
        //     dots: false,
        //     infinite: false,
        //     centerMode: true,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     variableWidth: true,
        // };
        const { byo_detail, byo_images } = this.state
        return (
            <Layout {...this.props} layout="shrink">
                <Helmet>
                    <title>{byo_detail ? `${byo_detail.name}` : ""}</title>
                    <meta name="description" content={byo_detail ? `${byo_detail.description}` : ""} />
                </Helmet>
                <div className="homepage">
                    <div className="container">
                        <div className="row">
                            {Cookies.get('token') ?
                                <Fragment>
                                    <div className="col-12 col-md-6">
                                        <div className="wrap_welcome">
                                            <h2>Hi, <span>{this.props.profile_data.first_name ? this.props.profile_data.first_name : ""} {this.props.profile_data.last_name ? this.props.profile_data.last_name : ""}</span></h2>
                                            <div>
                                                <Link className="manage_account_link" to="/profile#byo">
                                                    Manage Account
                                                    <img src="/images/next.svg" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {/* <div className="wrap_welcome"> */}
                                        {/* <h3 style={{visibility:"hidden"}} >Logout</h3> */}
                                        <button className="logout_btn" to='/login' onClick={(e) => this.props.signOut(true)}>
                                            Logout
                                            <img src="/images/logout.svg" alt="" />
                                        </button>
                                        {/* </div> */}
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <div className="col-12 col-md-6">
                                        <div className="wrap_welcome">
                                            <h2>Welcome to Cellarmasters <div>
                                                <span>BYO Finder</span>
                                            </div></h2>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 wrap_right">
                                        <div className="wrap_welcome">
                                            <h3>Manage your BYO's</h3>
                                            <Link className="sign_in_btn" to='/login' >Sign In</Link> <span>|</span> <Link to='/signup' className="sign_in_btn">Register</Link>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
                {byo_detail &&
                    <div className="byo-detail">
                        <div className="byo-main-detail">
                            <div className="container">
                                <h5 className="inner_back" style={{ textAlign: "initial" }}>
                                    <img onClick={this.props.history.goBack} src="/images/back.svg" alt="" />
                                    {byo_detail.b_y_o.name}
                                </h5>
                                <div className="full_byo_container">
                                    <div className="full_det_header">
                                        <img src={byo_detail.b_y_o.logo} alt="" />
                                        <h1>{byo_detail.b_y_o.name}</h1>
                                        {(byo_detail.b_y_o.is_claimed && byo_detail.accepted) &&
                                            <button className="edit" onClick={(e) => this.props.history.push(`/byo/edit/${byo_detail.b_y_o.id}`)}><i className="material-icons">edit</i>Edit BYO</button>
                                        }
                                        {/* {(byo_detail.b_y_o.is_claimed && !byo_detail.b_y_o.owner && byo_detail.accepted) &&
                                            <button title="Already Claimed" style={{ color: "#fff", backgroundColor: "#d7be7f" }} className="claimed_button" disabled>Accepted</button>
                                        } */}
                                        {(byo_detail.b_y_o.is_claimed && !byo_detail.b_y_o.owner && !byo_detail.accepted) &&
                                            <button title="Already Claimed" style={{ color: "#fff", backgroundColor: "#d7be7f" }} className="claimed_button" disabled>Claimed</button>
                                        }
                                        {/* {!byo_detail.b_y_o.is_claimed &&
                                            <button className="claim_now" onClick={(e)=>{this.props.history.push(`/claim/${byo_detail.b_y_o.id}`)}}>Claim</button>
                                        } */}
                                    </div>
                                    <div className="fit_image_crop">
                                        <img src={byo_detail.b_y_o.cover_image} alt="" />
                                    </div>
                                    <div className="other_byo_images">
                                        {byo_images.map(function (el, index) {
                                            return (
                                                <a key={`other_image_${index}`} data-fancybox="gallery" href={el.image}><img alt="byo" src={el.image} /></a>
                                            )
                                        })}
                                        {this.state.next_page &&
                                            <button className="view_more_imgs" onClick={this.handleMoreImages.bind(this)}>View More +</button>
                                        }
                                        {/* <Slider {...settings}>
                                            {byo_images.map(function (el, index) {
                                                return (
                                                    <div>
                                                        <a key={`other_image_${index}`} data-fancybox="gallery" href={el.image}><img alt="byo" src={el.image} /></a>
                                                    </div>
                                                )
                                            })}
                                        </Slider> */}
                                        {/* {this.state.next_page && <button onClick={this.getMoreImages} className="more_images_view"><i className="material-icons">navigate_next</i></button>} */}
                                    </div>
                                    <div className="description_byo_det">
                                        <h3>About</h3>
                                        <p>{byo_detail.b_y_o.description ? byo_detail.b_y_o.description : "No Information Available"}</p>
                                    </div>
                                    <table className="byo_details_table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <b>Phone</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.phone ? byo_detail.b_y_o.phone : "No Information Available"}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>Email</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.email ? byo_detail.b_y_o.email : "No Information Available"}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Country</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.country}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>State</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.state}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>City</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.city}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>Postcode</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.zipcode}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Address</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.address}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>Website</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.website ? byo_detail.b_y_o.website : "No Information Available"}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Sitting Capacity</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.sitting_capacity ? byo_detail.b_y_o.sitting_capacity : "No Information Available"}
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>Corkage Fee</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.corkage_fee ? `$ ${byo_detail.b_y_o.corkage_fee}` : "No Information Available"}
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    <b>Entry Fee</b>
                                                    <div className="value_fields">
                                                        {byo_detail.b_y_o.entry_fee ? `$ ${byo_detail.b_y_o.entry_fee}` : "No Information Available"}
                                                    </div>
                                                </td> */}
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>ID Proof</b>
                                                    <div className="value_fields">
                                                        <a href={byo_detail.id_proof} download target="_blank" rel="noopener noreferrer">Download</a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>Registration Copy</b>
                                                    <div className="value_fields">
                                                        <a href={byo_detail.byo_registration_copy} download target="_blank" rel="noopener noreferrer">Download</a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Other Documents</b>
                                                    <div className="value_fields">
                                                        {byo_detail.other_document &&
                                                            <a href={byo_detail.other_document} download target="_blank" rel="noopener noreferrer">Download</a>
                                                        }
                                                        {!byo_detail.other_document &&
                                                            <p>Not Available</p>
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <b>ABN Number</b>
                                                    <div className="value_fields">
                                                        {byo_detail.abn}
                                                    </div>
                                                </td>
                                            </tr>
                                            {/* <tr>
                                               
                                            </tr> */}
                                            {/* <tr>
                                                <td colSpan="2">
                                                    <b>Cuisines</b>
                                                    <div className="value_fields">
                                                        <ul>
                                                            {byo_detail.b_y_o.cuisines.map(function(el,index){
                                                                return <li className="image" key={`cuisine${index}`}> <img src={el.image} alt=""/> {el.name}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <b>Facilities</b>
                                                    <div className="value_fields">
                                                        <ul>
                                                            {byo_detail.b_y_o.facilities.map(function(el,index){
                                                                return <li key={`facilities${index}`}>{el.name}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <b>Payments Accepted</b>
                                                    <div className="value_fields">
                                                        <ul>
                                                            {byo_detail.b_y_o.payment_types.map(function(el,index){
                                                                return <li key={`payment_types${index}`}>{el.name}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {!byo_detail &&

                    <div style={{ minHeight: "calc(100vh - 263px)" }}>
                        <p className="no_byo_found">
                            <img src="/images/search_ico.svg" alt="" />
                            No BYO found </p>
                    </div>

                }
            </Layout>
        )
    }
}