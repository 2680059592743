import React, { Component } from "react";
import 'react-dates/initialize';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
import Layout from "../../layout";
import { Link } from "react-router-dom";
import { validation } from '../../global/custom_validator';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-dates/lib/css/_datepicker.css';
import Helmet from 'react-helmet';
import Cookies from 'js-cookie';
var dateFormat = require('dateformat');

// let date_validate = new Date();
// alert(date_validate)
// let full_date = new Date(`${(parseInt(date_validate.getFullYear()) - 18)}-${date_validate.getMonth() + 1}-${date_validate.getDate()}`)
// console.error("initialDate=", moment(full_date))
export default class Signup extends Component {
  state = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    focused: false,
    //confirm_password: "",
    password_visible: false,
    d_o_b: null,
  }
  componentDidMount() {
    this.onFocusChange = this.onFocusChange.bind(this);
    (function () {
      window.scrollTo(0, 0)
    })()


  }
  onFocusChange({ focused }) {
    this.setState({ focused });
  }
  onDateChange(date) {
    if (date) {
      if (this.isDate18orMoreYearsOld(date.toDate().getDate(), date.toDate().getMonth(), date.toDate().getFullYear())) {
        this.setState({ d_o_b: date });
      } else {
        //this.props.error('Please confirm you are over 18 years old');
        this.props.stringFile('confirm_dob');
      }
    } else {
      this.setState({ d_o_b: null });
    }
  }

  isDate18orMoreYearsOld(day, month, year) {
    return new Date(year + 18, month - 1, day) <= new Date();
  }

  handleSubmitForm = (e) => {
    e.preventDefault();
    if (validation('signup_form')) {
      // if (this.state.password !== this.state.confirm_password) {
      //   document.getElementById('confirm_password_error').innerHTML = "password and Confirm password is not same!"
      //   return true;
      // }

      if (this.state.phone) {
        if (!isValidPhoneNumber(this.state.phone)) {
          document.getElementById('phone_error').innerHTML = "Invalid Number!"
          return true;
        }
      }

      let rowData = {
        "first_name": this.state.firstName,
        "last_name": this.state.lastName,
        "password": this.state.password,
        "email": this.state.email,
        "phone": this.state.phone,

      }
      if (this.state.d_o_b) {
        rowData.d_o_b = dateFormat(this.state.d_o_b, "yyyy-mm-dd");
      } else {
        if (!window.confirm(this.props.stringFile('confirm_dob',false,false))) {
          return true;
        }
      }
      this.props.axios.post('register/', rowData, { is_show_error: true }).then(res => {
        if (res.data.status.code === 200) {
          if (res.data.status.code === 200) {
            // if (res.data.data.is_email_verified) {
            Cookies.set('token', res.data.data.access_token, { expires: 7 })
            Cookies.set('refresh_token', res.data.data.refresh_token, { expires: 7 })
            this.props.handleProfile();
            // this.props.history.push("/email-verify");
            // }
          }
        }
      })
    }
  }


  returnYears = () => {
    let years = []
    for (let i = moment().year() - 100; i <= moment().year() + 5; i++) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  }
  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <select
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {this.returnYears()}
        </select>
      </div>
    </div>

  render() {
    const { focused, d_o_b } = this.state;
    return (
      <Layout {...this.props} layout="shrink">
        <Helmet>
          <title>Signup Form</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="auth_container">
          <div className="login_header">
            <div className="container">
              <h1 className="heading_with_back">
                <span className="back_button" onClick={() => { this.props.history.goBack() }}>
                  <img src="/images/back.svg" alt="Go back to homepage" />
                </span>{" "}
                Sign Up
              </h1>
            </div>
          </div>
          <div className="auth_body">
            <div className="container">
              <form onSubmit={this.handleSubmitForm} id="signup_form" noValidate={true} className="signup_form">
                <div className="row">
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="first_name">First Name<span className="mandatory_astrick">*</span></label>
                    <input id="first_name" type="text" onChange={(e) => this.setState({ firstName: e.target.value })} value={this.state.firstName} required />
                    <div id="first_name_error" className="has-error"></div>
                  </div>
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="last_name">Last Name<span className="mandatory_astrick">*</span></label>
                    <input id="last_name" type="text" onChange={(e) => this.setState({ lastName: e.target.value })} value={this.state.lastName} required />
                    <div id="last_name_error" className="has-error"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="email">Email Address<span className="mandatory_astrick">*</span></label>
                    <input autoComplete="new-email" id="email" type="email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} required />
                    <div id="email_error" className="has-error"></div>
                  </div>
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="phone">Phone Number</label>
                    {/* <input id="phone" type="number" onChange={(e) => this.setState({ phone: e.target.value })} required /> */}
                    <PhoneInput
                      placeholder=" "
                      value={this.state.phone}
                      country="AU"
                      id="phone"
                      onChange={phone => this.setState({ phone })}
                    />
                    <div id="phone_error" className="has-error"></div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="website">Website</label>
                    <input id="website" type="url" />
                  </div>
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="city">City</label>
                    <input id="city" type="text" />
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="state">State</label>
                    <input id="state" type="text" />
                  </div>
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="zip">Zip</label>
                    <input id="zip" type="number" />
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-12 col-md-12">
                    <label htmlFor="state">Address</label>
                    <input id="state" type="text" />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="password">Password<span className="mandatory_astrick">*</span></label>
                    <i className="material-icons" style={{ position: "absolute", top: "43px", right: "20px", cursor: "pointer" }} onClick={(e) => { this.setState({ password_visible: !this.state.password_visible }) }}>remove_red_eye</i>

                    <input
                      autoComplete="new-password"
                      id="password"
                      type={!this.state.password_visible ? "password" : "text"}
                      data-error={this.state.password ? this.props.stringFile('is_valid_password',false)  : this.props.stringFile('is_required_password',false)}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      pattern="^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{0,}$"
                      value={this.state.password}
                      required
                    />
                    <div id="password_error" className="has-error"></div>

                  </div>
                  {/* <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="confirm_password">Confirm Password</label>
                    <input id="confirm_password" type="password" onChange={(e) => this.setState({ confirm_password: e.target.value })} value={this.state.confirm_password} pattern="^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$" datamatch="password" datamatcherror="Confirm password data and password is different!" required />
                    <div id="confirm_password_error" className="has-error"></div>
                  </div> */}
                  <div className="col-12 col-md-6 custom_error">
                    <label htmlFor="d_o_b">Birth Date</label>
                    {/* <input id="d_o_b" type="date" onChange={(e) => this.setState({ d_o_b: e.target.value })} value={this.state.d_o_b} /> */}
                    <SingleDatePicker
                      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                      // isOutsideRange={day =>  isAfterDay(full_date, moment()) || isBeforeDay(day, moment('2017-01-01'))}
                      date={d_o_b}
                      // initialDate={moment(full_date)}
                      focused={focused}
                      onFocusChange={this.onFocusChange.bind(this)}
                      onDateChange={this.onDateChange.bind(this)}
                      numberOfMonths={1}
                      placeholder="Date of Birth"
                      displayFormat='DD-MM-YYYY'
                      renderMonthElement={this.renderMonthElement}
                    />
                    <div id="d_o_b_error" className="has-error"></div>
                  </div>
                </div>
                {/* <div className="row">
                  
                </div> */}
                <div className="form_group">
                  <p className="terms_p">By signing up you are agreeing to our <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms of service</a> and <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                </div>
                <div className="form_group">
                  <button className="login_button">Sign Up</button>
                  <div className="ask_account_exists">Have an account? <Link to='/login'>Login</Link></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
