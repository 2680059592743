import React, { Component, Fragment } from 'react'
import Select from 'react-select';
import Layout from '../../layout';
import './byo.scss'
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { compose, withProps, lifecycle, withStateHandlers } from "recompose";
import { validation } from '../../global/custom_validator';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Geosuggest from 'react-geosuggest';
import CityJson from '../../global/cityjson';
import { Link } from 'react-router-dom'
import ImageUpload from './image-upload';
import Helmet from 'react-helmet'
import '../../global/geo-suggest.scss';
import $ from 'jquery';
import { Prompt } from 'react-router'
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
const cloneDeep = require('clone-deep');

const format = 'HH:mm';

// const now = moment().hour(0).minute(0);
const queryString = require('query-string');

const google = window.google;
const MyMapComponent = compose(
    withProps({
        loadingElement: <div style={{ height: `100%`, borderRadius: `20px` }} />,
        containerElement: <div style={{ height: `300px`, borderRadius: `20px` }} />,
        mapElement: <div style={{ height: `100%`, borderRadius: `20px` }} />,
    }),
    withGoogleMap,
    withStateHandlers(() => ({
        isOpen: false,
    }), {
            onToggleOpen: ({ isOpen }) => () => ({
                isOpen: !isOpen,
            })
        }),
    lifecycle({
        componentWillMount() {
            var geocoder = new google.maps.Geocoder();
            const refs = {}
            this.setState({
                onMarkerMounted: ref => {
                    refs.marker = ref;
                },
                onMapMounted: ref => {
                    refs.map = ref;
                },
                onDragEnd: (data) => {
                    var self = this;
                    geocoder.geocode({
                        latLng: refs.marker.getPosition()
                    }, function (responses) {
                        if (responses && responses.length > 0) {
                            var postal_code, country, state, city;
                            responses[0].address_components.map(function (item) {
                                item.types.map(function (type, index) {
                                    if (type === "postal_code") {
                                        postal_code = parseInt(item.long_name);
                                    }
                                    if (type === "country") {
                                        country = item.long_name;
                                    }
                                    if (type === "administrative_area_level_1") {
                                        state = item.long_name;
                                    }
                                    if (type === "locality") {
                                        city = item.long_name;
                                    }
                                    return type;
                                })
                                return item;
                            })
                            self.props.getAddress(responses[0].formatted_address, postal_code, country, state, city);
                            self.props.getLatLng(data.latLng);
                        } else {
                            self.props.getAddress('Not Found');
                        }
                    }, this);
                },
                onMapDragEnd: () => {
                    var data = refs.map.getCenter();
                    var self = this;
                    geocoder.geocode({
                        latLng: refs.map.getCenter()
                    }, function (responses) {
                        if (responses && responses.length > 0) {
                            var postal_code, country, state, city
                            responses[0].address_components.map(function (item) {
                                item.types.map(function (type, index) {
                                    if (type === "postal_code") {
                                        postal_code = item.long_name;
                                    }
                                    if (type === "country") {
                                        country = item.long_name;
                                    }
                                    if (type === "administrative_area_level_1") {
                                        state = item.long_name;
                                    }
                                    if (type === "locality") {
                                        city = item.long_name;
                                    }
                                    return type;
                                })
                                return item;
                            })

                            self.props.getAddress(responses[0].formatted_address, postal_code, country, state, city);
                            self.props.getLatLng(data);
                            self.props.check_drag("false")
                        } else {
                            self.props.getAddress('Not Found');
                        }
                    }, this);
                },
                onMapDrag: () => {
                    this.props.check_drag("true")
                }
            })
        },
        componentWillReceiveProps(newProps) {
            if (newProps !== this.props) {
                this.setState({ lat: newProps.lat, lng: newProps.lng })
            }
        },
    })
)((props) =>

    <GoogleMap defaultZoom={6} center={{ lat: props.lat, lng: props.lng }} draggable={true} onDragStart={props.onMapDrag} onDragEnd={props.onMapDragEnd} ref={props.onMapMounted}>
        {(props.isMarkerShown && (props.is_map_dragging === "false" || props.is_map_dragging === undefined)) &&
            <Marker
                position={{ lat: props.lat, lng: props.lng }}
                title={props.address}
                onClick={props.onToggleOpen}
                ref={props.onMarkerMounted}
            >
                {props.isOpen &&
                    <InfoWindow onCloseClick={props.onToggleOpen}>
                        <p className="map_marker_info">{props.address}</p>
                    </InfoWindow>
                }
            </Marker>
        }

    </GoogleMap>
)
export default class BYOform extends Component {
    state = {
        selectedOption: null,
        name: "",
        city: "",
        state: "",
        country: "Australia",
        address: "",
        zipcode: "",
        logo: "",
        cover_image: "",
        selected_cuisine: "",
        image_object: "",
        sitting_capacity: "",
        cover_image_changed: "",
        cover_image_object: "",
        image_changed: "",
        next_url: "",
        entry_fee: "",
        corkage_fee: "",
        byotiming_set: [{ id: '0', day: 0, day_name: "Monday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
        { id: '0', day: 1, day_name: "Tuesday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
        { id: '0', day: 2, day_name: "Wednesday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
        { id: '0', day: 3, day_name: "Thursday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
        { id: '0', day: 4, day_name: "Friday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
        { id: '0', day: 5, day_name: "Saturday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
        { id: '0', day: 6, day_name: "Sunday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false }],
        step: 1,
        phone: "",
        logo_boundaries: [],
        cover_image_boundaries: [],
        cover_image_file: "",
        logo_file: "",
        description: "",
        other_images: [],
        search_type: false,
        cuisines_list: [],
        facility_list: [],
        selected_facility: "",
        form_Incomplete: false,
        location: { latitude: -25.274398, longitude: 133.77513599999997 },
        is_notify: false,
        selected_payment_type: '',
        payment_list: [],
        web_url: '',
        email: '',
        byo_suggest_id: '',
        state_options: [],
        detail_error: [],
        abn: ""
    }

    componentDidMount() {
        (function () {
            window.scrollTo(0, 0)
        })()
        this.getCuisines();
        this.getFacility();
        this.getPaymentType();
        if (this.props.match.params.id && this.props.match.params.type === "edit") {
            this.getData("byo/" + this.props.match.params.id + "/");
            this.getOtherImages("byo-image/");
        }
        let state_options = []
        if (this.state.country) {
            CityJson[this.state.country].map((i) => {
                state_options.push({ label: i, value: i })
                return i;
            })
            //console.error(state_options)
            // state_options = CityJson[this.state.country];
        }
        this.setState({ state_options: state_options })
    }

    getData(url) {
        this.props.axios.get(url).then((res) => {
            if (res.data.status.code === 200) {
                var x = res.data.data;
                var byotiming = this.state.byotiming_set;
                let today = new Date();
                x.byotiming_set.map((i, index) => {
                    byotiming[i.day].id = i.id;
                    let from_time_split = i.from_time.split(":")
                    let to_time_split = i.to_time.split(":")
                    byotiming[i.day].from_time = moment(today.setHours(from_time_split[0], from_time_split[1]));
                    byotiming[i.day].to_time = moment(today.setHours(to_time_split[0], to_time_split[1]));
                    byotiming[i.day].is_checked = true;
                    byotiming[i.day].is_new = false;
                    return i;
                    // byotiming[i.day].is_show_tick = true
                })

                x.cuisines.map((i) => {
                    i['value'] = i.id
                    i['label'] = i.name
                    return i
                })
                x.facilities.map((i) => {
                    i['value'] = i.id
                    i['label'] = i.name
                    return i
                })

                let state_option = []
                if (x.country) {
                    //state_option = CityJson[x.country]
                    CityJson[x.country].map((i) => {
                        state_option.push({ label: i, value: i })
                        return i;
                    })
                }

                this.setState({
                    state_options: state_option,
                    name: x.name,
                    description: x.description ? x.description : "",
                    city: x.city,
                    country: x.country,
                    state: { value: x.state, label: x.state },
                    logo: x.logo,
                    email: x.email,
                    web_url: x.website,
                    cover_image: x.cover_image,
                    sitting_capacity: x.sitting_capacity ? x.sitting_capacity : "",
                    phone: x.phone,
                    address: x.address,
                    entry_fee: x.entry_fee ? x.entry_fee : "",
                    corkage_fee: x.corkage_fee ? x.corkage_fee : "",
                    byotiming_set: byotiming,
                    selected_cuisine: x.cuisines,
                    zipcode: x.zipcode,
                    form_Incomplete: false,
                    selected_facility: x.facilities,
                    selected_payment_type: x.payment_types
                }, () => {
                    console.log("abc", this.state.byotiming_set)
                })
            }
        })
    }

    getOtherImages(url) {
        this.props.axios.get(url, { params: { b_y_o_id: this.props.match.params.id } }).then((res) => {
            if (res.data.status.code === 200) {
                this.setState({
                    other_images: res.data.data,
                    next_url: res.data.next,
                })
            }
        })
    }



    getCuisines() {
        this.props.axios.get("cuisines/").then((res) => {
            if (res.data.status.code === 200) {
                if (res.data.data.length > 0) {
                    res.data.data.map((i) => {
                        i['value'] = i.id
                        i['label'] = i.name
                        return i;
                    })
                }
                this.setState({
                    cuisines_list: res.data.data
                })
            }
        })
    }

    getFacility() {
        this.props.axios.get("byo-facilities/").then((res) => {
            if (res.data.status.code === 200) {
                if (res.data.data.length > 0) {
                    res.data.data.map((i) => {
                        i['value'] = i.id
                        i['label'] = i.name
                        return i;
                    })
                }
                this.setState({
                    facility_list: res.data.data
                })
            }
        })
    }

    getPaymentType() {
        this.props.axios.get("payment-types/").then((res) => {
            if (res.data.status.code === 200) {
                this.setState({ payment_list: res.data.data })
            }
        })
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    }

    handleFirstStep = (e) => {
        e.preventDefault();
        if (validation('first_step_form')) {
            if (this.state.phone) {
                if (!isValidPhoneNumber(this.state.phone)) {
                    this.props.stringFile('is_valid_number')
                    // document.getElementById('phone_error').innerHTML = "Invalid Number!";
                    // document.getElementById('phone_error').focus();
                    return true;
                }
            } else {
                // document.getElementById('phone_error').innerHTML = "phone number is required!"
                // document.getElementById('phone_error').focus();
                this.props.stringFile('is_required_number')
                return true;
            }
            // if (parseFloat(this.state.entry_fee) > 9999.99) {
            //     document.getElementById('entryfee_error').innerHTML = "Value must be less than or equal to 9999.99."
            //     document.getElementById('entry_fee').focus();
            //     return true;
            // }
            // if (parseFloat(this.state.corkage_fee) > 9999.99) {
            //     document.getElementById('corkage_fee_error').innerHTML = "Value must be less than or equal to 9999.99."
            //     document.getElementById('corkage_fee').focus();
            //     return true;
            // }
            if (!this.state.state) {
                this.props.stringFile("is_required_state");
                return true;

            }

            this.setState({ step: 2 }, () => {
                (function () {
                    window.scrollTo(0, 0)
                })()
            })
        }
    }

    handleSecondStep = (e) => {
        e.preventDefault();
        if (validation('second_step_form')) {
            if (!this.state.selected_cuisine) {
                this.props.stringFile('is_cuisine_required')
                return
            }
            let is_wrong_time = false;
            this.state.byotiming_set.map((i) => {
                if (i.is_checked) {
                    if (i.from_time === null || i.to_time === null) {
                        is_wrong_time = true;
                    }
                }
                return i;
            })
            if (is_wrong_time) {
                this.props.stringFile('is_required_time');
                return true;
            }
            var counter = 0
            $(".mdi-check").each(function () {
                var a = this.id;
                if (!$('#' + a).is(':hidden')) {
                    counter++;
                }
            })
            if (counter > 0) {
                this.props.error('Please save time!')
            } else {
                this.setState({ step: 3 }, () => {
                    $(".has-error").html('');
                    (function () {
                        window.scrollTo(0, 0)
                    })()
                })
            }
        }
    }

    handleThirdStep = (e) => {
        e.preventDefault();
        if (validation('first_step_form')) {
            this.setState({ step: 2 }, () => {
                (function () {
                    window.scrollTo(0, 0)
                })()
            })
        }
    }

    convertTime12to24(time12h) {

        var time = time12h;
        var hours = Number(time.match(/^(\d+)/)[1]);
        var minutes = Number(time.match(/:(\d+)/)[1]);
        var AMPM = time.match(/\s(.*)$/)[1];
        if (AMPM === "PM" && hours < 12) hours = hours + 12;
        if (AMPM === "AM" && hours === 12) hours = hours - 12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        let today = new Date();
        today.setHours(sHours, sMinutes);
        return moment(today)
        // return (sHours + ":" + sMinutes);
    }

    get_place_data(i) {

        var self = this;
        var request = {
            placeId: i.placeId,
        };
        var container = document.getElementById('google_place_search');
        var service = new google.maps.places.PlacesService(container);
        service.getDetails(request, callback);
        function callback(place, status) {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
                return
            };
            var city = "";
            var state = "";
            var country = "";
            var postal_code = "";
            place.address_components.map(function (j) {
                if (j.types.includes("postal_code")) {
                    postal_code = j.short_name
                }
                if (j.types.includes("administrative_area_level_2")) {
                    city = j.long_name
                }
                if (j.types.includes("administrative_area_level_1")) {
                    state = j.long_name
                }
                if (j.types.includes("country")) {
                    country = j.long_name
                }
                return j;
            })
            // var other_images = [];
            // var cover_image = "";
            // var cover_image_changed = false;
            // if (place.photos) {
            //     if (place.photos.length > 0) {
            //         cover_image_changed = true;
            //         cover_image = place.photos[0].getUrl({ 'maxWidth': place.photos[0].width, 'maxHeight': place.photos[0].height })
            //         other_images = [{ id: 0, image: place.photos[0].getUrl({ 'maxWidth': place.photos[0].width, 'maxHeight': place.photos[0].height }) }]
            //     }
            //     if (place.photos.length > 1) {
            //         place.photos.map(function (j) {
            //             other_images.push({ id: 0, image: j.getUrl({ 'maxWidth': j.width, 'maxHeight': j.height }) })
            //             return j;
            //         })
            //     }
            // }
            // var opening_hours = [];
            // if (place.opening_hours) {
            //     place.opening_hours.weekday_text.map(function (i, index) {
            //         var details = i.split(':')

            //         var from_time = null;
            //         var to_time = null;
            //         if (details.length === 4) {
            //             var time = details[2].split("–");
            //             from_time = self.convertTime12to24([$.trim(details[1]), time[0]].join(":")) /* convertTime12to24([details[1],details[2]].join(" ")) */
            //             to_time = self.convertTime12to24([$.trim(time[1]), details[3]].join(":"))   /*  convertTime12to24([details[4],details[5]].join(" ")) */
            //         }
            //         opening_hours.push({
            //             day_name: details[0],
            //             day: index,
            //             from_time: from_time,
            //             to_time: to_time,
            //             is_checked: details.length === 4 ? true : false,
            //             is_new: true,
            //         })
            //         return i
            //     })
            // } else {
            //     opening_hours = self.state.byotiming_set;
            // }
            let state_options = []
            if (country) {
                // state_options = CityJson[country];
                CityJson[country].map((i) => {
                    state_options.push({ label: i, value: i })
                    return i;
                })
            }
            self.setState({
                // description: place.description,
                location: { latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() },
                address: place.formatted_address,
                // phone: place.international_phone_number ? place.international_phone_number : '',
                // logo: place.icon ? place.icon : '',
                city: city,
                state: { label: state, value: state },
                country: country,
                zipcode: postal_code,
                // name: place.name,
                state_options: state_options,
                // cover_image: cover_image,
                // image_changed: true,
                // cover_image_changed: cover_image_changed,
                // other_images: other_images,
                // byotiming_set: opening_hours,
            }, () => {
                console.log("SAdsadsad", self.state.logo, self.state.byotiming_set)
            })
        }

    }

    onSuggestSelect(suggest) {
        console.log('option', suggest, this.state.search_type)
        if (suggest) {
            if (suggest.placeId) {
                // if (this.state.search_type) {
                //     this.get_place_data(suggest);
                //     this.geosuggest.clear();
                //     this.setState({ address: "", image_changed: false, cover_image_changed: false, city: "", state: "", country: "", zipcode: "" });
                // } else {
                var city = "";
                var state = "";
                var country = "";
                var postal_code = "";
                suggest.gmaps.address_components.map(function (j) {
                    if (j.types.includes("postal_code")) {
                        postal_code = j.short_name
                    }
                    if (j.types.includes("administrative_area_level_2")) {
                        city = j.long_name
                    }
                    if (j.types.includes("administrative_area_level_1")) {
                        state = j.long_name
                    }
                    if (j.types.includes("country")) {
                        country = j.long_name
                    }
                    return j;
                })

                let state_options = []
                if (country) {
                    //state_options = CityJson[country];
                    CityJson[country].map((i) => {
                        state_options.push({ label: i, value: i })
                        return i;
                    })
                }
                this.setState({
                    address: suggest.gmaps.formatted_address,
                    location: { latitude: suggest.location.lat, longitude: suggest.location.lng },
                    city: city,
                    state_options: state_options,
                    state: { label: state, value: state },
                    country: country,
                    zipcode: postal_code,
                })
                // }
            }

        }

    }
    onSuggestNoResults(userInput) {
        console.log(userInput)
        if (!userInput) {
            this.geosuggest.clear();
            this.setState({
                address: "",
            }, function () {
            })
        } else {
            this.setState({ address: userInput })
        }
    }

    handleCuisine(option) {
        option ? this.setState({ selected_cuisine: option }) : this.setState({ selected_cuisine: '' });
    }

    handleState(option) {
        option ? this.setState({ state: option }) : this.setState({ state: '' });
    }

    handleFacility(option) {
        option ? this.setState({ selected_facility: option }) : this.setState({ selected_facility: '' });
    }

    getAddress(address, postal_code, country, state, city) {
        if (address === "Not Found") {
            this.setState({ address: "", postal_code: "", city: "", state: "", country: "" })
        }
        else {
            this.setState({
                address: address,
                zipcode: postal_code ? postal_code : "",
                city: city ? city : "",
                country: country ? country : "",
                state: state ? state : "",
            })
        }
    }

    is_map_dragging(value) {
        this.setState({ is_map_dragging: value })
    }

    getLatLng(pos) {
        var location = { latitude: pos.lat(), longitude: pos.lng() }
        this.setState({ location: location }, () => {
            this.setState({ location: location }, () => {
                console.log('Location', this.state.location, this.state.location)
            })
        })
    }

    onOverlayComplete(e) {
    }

    handleReset() {
        this.setState({
            name: "",
            city: "",
            state: "",
            country: "",
            address: "",
            zipcode: "",
            logo: "",
            cover_image: "",
            selected_cuisine: "",
            image_object: "",
            sitting_capacity: "",
            cover_image_changed: "",
            cover_image_object: "",
            image_changed: "",
            abn : "",
            entry_fee: "",
            corkage_fee: "",
            byotiming_set: [{ id: '0', day: 0, day_name: "Monday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
            { id: '0', day: 1, day_name: "Tuesday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
            { id: '0', day: 2, day_name: "Wednesday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
            { id: '0', day: 3, day_name: "Thursday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
            { id: '0', day: 4, day_name: "Friday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
            { id: '0', day: 5, day_name: "Saturday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false },
            { id: '0', day: 6, day_name: "Sunday", from_time: moment(), to_time: moment(), is_checked: false, is_new: true, is_show_tick: false }],
            // step: 1,
            phone: "",
            logo_boundaries: [],
            cover_image_boundaries: [],
            cover_image_file: "",
            logo_file: "",
            description: "",
            other_images: [],
            form_Incomplete: false,
            email: "",
            website: "",
            search_type: false,
            // cuisines_list: [],
            // facility_list: [],
            selected_facility: "",
            selected_payment_type: '',
            location: { latitude: -25.274398, longitude: 133.77513599999997 },
            web_url: '',
            byo_suggest_id: '',
            id_proof: "",
            reg_certificate: "",
            other_certificate: "",
        })
    }

    handleTimingStatus(i, index) {
        let byotiming_set = this.state.byotiming_set;
        if (i.is_checked) {
            // this.props.match.params.id && 
            if (!i.is_new) {
                this.removeTime(i.id, i.day);
            } else {
                byotiming_set[index].is_checked = !i.is_checked;
                // byotiming_set[index].from_time = ""
                // byotiming_set[index].to_time = ""
                this.setState({
                    byotiming_set: byotiming_set,
                })
            }

        } else {
            byotiming_set[index].is_checked = !i.is_checked;
            // byotiming_set[index].from_time = ""
            // byotiming_set[index].to_time = ""

            this.setState({
                byotiming_set: byotiming_set,
            })
        }
    }

    removeTime(id, day) {
        if (id !== '0') {
            if (window.confirm("Are you sure you want to delete this time ?")) {
                this.props.axios.delete(`byo-timing/${id}/`).then((res) => {
                    if (res.data.status.code === 200) {
                        let byotiming_set = this.state.byotiming_set
                        byotiming_set[day].id = "0";
                        byotiming_set[day].from_time = moment();
                        byotiming_set[day].to_time = moment();
                        byotiming_set[day].is_checked = false;
                        byotiming_set[day].is_new = true;
                        this.setState({
                            byotiming: byotiming_set
                        })
                    }
                })
            }
        }
    }

    handleTimings(index, type, event) {
        // if (this.props.match.params.id) {
        //     $("#time_update_" + index).css("display", "block")
        // }
        console.error("ssssss", index, type, event)
        let byotiming_set = this.state.byotiming_set;
        if (type === "from") {
            byotiming_set[index].from_time = event
            byotiming_set[index].is_show_tick = true
        }
        if (type === "to") {
            byotiming_set[index].to_time = event
            byotiming_set[index].is_show_tick = true
        }
        this.setState({
            byotiming_set: byotiming_set,
        })
    }


    updateTime() {

        let timing = [];
        let time = cloneDeep(this.state.byotiming_set);
        console.log(time, this.state.byotiming_set)
        time.map((i, index) => {
            if (i.is_checked) {
                i.from_time = i.from_time.format(format);
                i.to_time = i.to_time.format(format)
                timing.push(i);
            }
            return i;
        })


        let rowdata = {};

        rowdata.b_y_o_id = this.props.match.params.id
        rowdata.byotiming_set = timing;
        //    rowdata.from_time = this.state.byotiming_set[day].from_time
        //    rowdata.to_time = this.state.byotiming_set[day].to_time
        this.props.axios.post(`byo-timing/`, rowdata, { is_show_error: true }).then((res) => {
            timing.length = 0;
            if (res.data.status.code === 200) {
                this.updateBYODeatil();
                // $("#time_update_" + day).css("display", "none");
                // let byotiming_set = this.state.byotiming_set;
                // byotiming_set[day].id = res.data.data.id;
                // byotiming_set[day].is_new = false;
                // byotiming_set[day].is_show_tick = false
                // this.setState({
                //     byotiming_set: byotiming_set
                // })
            }
            else if (res.data.status.code === 403) {
                if (res.data.status.detail) {
                    let error = [];
                    for (let key in res.data.status.detail) {
                        if (res.data.status.detail.hasOwnProperty(key)) {
                            error.push(`${(key.split("_")).join(" ")} : ${res.data.status.detail[key][0]}`);
                        }
                    }
                    this.setState({ detail_error: error }, () => {
                        (function () {
                            window.scrollTo(0, 0)
                        })()
                    })
                }
            }
        })


    }

    handleprofile(logo, logo_boundaries) {
        if (logo.length > 10000) {
            this.setState({ logo: logo, image_changed: true, logo_boundaries: logo_boundaries })
        } else {
            this.setState({ image_changed: false })
        }
    }

    handleprofileImage(cover_image, cover_image_boundaries) {
        // console.error(cover_image, cover_image_boundaries)
        if (cover_image.length > 1500) {
            this.setState({ cover_image: cover_image, cover_image_changed: true, cover_image_boundaries: cover_image_boundaries })
        } else {
            this.setState({ cover_image_changed: false })
        }
    }

    handleCoverObject(e) {
        var cover_image_file = e.target.files[0];
        this.setState({
            cover_image_object: e.target.files,
            cover_image_file: cover_image_file
        })
    }

    handleprofileobject(e) {
        var logo_file = e.target.files[0];
        this.setState({
            image_object: e.target.files,
            logo_file: logo_file
        })
    }

    handleOtherImage(event) {
        event.preventDefault();
        var other_images = this.state.other_images;
        var files = document.getElementById("other_images").files;
        for (var i = 0; i < files.length; i++) {
            var img_split = files[i].type.split("/")
            if (img_split[0] === "image") {
                if (!this.props.match.params.id) {
                    var url = URL.createObjectURL(files[i])
                    other_images.push({ image_object: files[i], image: url, id: 0 });
                } else {
                    this.updateImage(files[i], "byo-image/");
                }
            } else {
                this.props.stringFile("is_valid_file_format");
            }
        }
        this.setState({
            other_images: other_images
        }, () => {
            //  URL.createObjectURL
            $("#other_images").val('')
        })

    }

    handleUploadDocument = (type, e) => {

        let file = e.target.files[0];
        if (file.type) {
            if (file.type.toLowerCase() === "image/jpeg" || file.type.toLowerCase() === "image/jpg" || file.type.toLowerCase() === "image/png" || file.type.toLowerCase() === "application/pdf") {
                if (((file.size / 1024) / 1024).toFixed(4) > 10) {
                    this.props.stringFile('file_size')
                } else {
                    if (type === "id_proof")
                        this.setState({ id_proof: file });
                    else if (type === "reg_certificate")
                        this.setState({ reg_certificate: file });
                    else
                        this.setState({ other_certificate: file });
                }
            } else {
                this.props.stringFile('accept_files');
            }
        }
        document.getElementById(`${type}`).value = "";
    }

    submitDoc(e) {
        e.preventDefault();
        if (!validation('verification_from')) {
            return true;
        }
        if (this.state.reg_certificate) {
            let formData = new FormData();
            formData.append("name", this.state.name);
            formData.append("city", this.state.city);
            formData.append("state", this.state.state.value);
            formData.append("country", this.state.country);
            formData.append("address", this.state.address);
            formData.append("phone", this.state.phone);
            formData.append("description", this.state.description);
            let cuisine = []
            if (this.state.selected_cuisine && this.state.selected_cuisine.length > 0) {
                this.state.selected_cuisine.map(function (i) {
                    cuisine.push(i.value)
                    return i;
                })
            }
            let facility = []
            if (this.state.selected_facility && this.state.selected_facility.length > 0) {
                this.state.selected_facility.map(function (i) {
                    facility.push(i.value)
                    return i;
                })
            }
            let payment_type = []
            if (this.state.selected_payment_type && this.state.selected_payment_type.length > 0) {
                this.state.selected_payment_type.map(function (i) {
                    payment_type.push(i.id)
                    return i;
                })
            }
            if (this.state.web_url) {
                formData.append('website', this.addhttp(this.state.web_url))
            } else {
                formData.append('website', this.state.web_url)
            }
            formData.append('email', this.state.email)

            formData.append('cuisines', JSON.stringify(cuisine))
            formData.append('facilities', JSON.stringify(facility))
            formData.append('payment_types', JSON.stringify(payment_type))
            formData.append("entry_fee", this.state.entry_fee ? parseFloat(this.state.entry_fee).toFixed(2) : "");
            formData.append("corkage_fee", this.state.corkage_fee);
            formData.append("zipcode", this.state.zipcode)
            formData.append("is_enabled", 'True')
            formData.append("sitting_capacity", this.state.sitting_capacity);
            formData.append("location", JSON.stringify(this.state.location));
            formData.append("abn",this.state.abn);
            formData.append('notify_users', this.state.is_notify ? 'True' : 'False')
            // formData.append('id_proof', this.state.id_proof);
            formData.append('byo_registration_copy', this.state.reg_certificate);
            // if (this.state.other_certificate)
            //     formData.append('other_document', this.state.other_certificate);

            let timing = [];
            let time = cloneDeep(this.state.byotiming_set);
            time.map((i, index) => {
                if (i.is_checked) {
                    console.log(i)
                    i.from_time = i.from_time.format(format);
                    i.to_time = i.to_time.format(format)
                    timing.push(i);
                }
                return i;
            })
            formData.append("byotiming_set", JSON.stringify(timing));
            this.props.axios.post('byo-request/', formData).then(res => {
                if (res.data.status.code === 200) {
                    this.setState({ step: 4 }, () => {
                        // this.handleReset();
                        (function () {
                            window.scrollTo(0, 0)
                        })()
                    })
                }
                else if (res.data.status.code === 403) {
                    if (res.data.status.detail) {
                        let error = [];
                        for (let key in res.data.status.detail) {
                            if (res.data.status.detail.hasOwnProperty(key)) {
                                error.push(`${(key.split("_")).join(" ")} : ${res.data.status.detail[key][0]}`);
                            }
                        }
                        this.setState({ detail_error: error }, () => {
                            (function () {
                                window.scrollTo(0, 0)
                            })()
                        })
                    }
                }
            })

        } else {
            this.props.stringFile('required_doc');
        }


    }

    addhttp(url) {
        if (!/^(f|ht)tps?:\/\//i.test(url)) {
            url = "http://" + url;
        }
        return url;
    }

    updateImage(file) {
        var formData = new FormData();
        formData.append("b_y_o_id", this.props.match.params.id);
        formData.append("image", file);
        this.props.axios.post("byo-image/", formData).then((res) => {
            if (res.data.status.code === 200) {
                let other_images = this.state.other_images;
                other_images.push(res.data.data);
                this.setState({
                    other_images: other_images
                })
            }
        })
    }

    handleRemoveImage(id, index, e) {
        e.preventDefault();
        if (id !== 0) {
            this.props.axios.delete("byo-image/" + id + "/").then((res) => {
                if (res.data.status.code === 200) {
                    let other_images = this.state.other_images;
                    other_images.splice(index, 1);
                    this.setState({ other_images: other_images }, () => {
                        console.log(this.state.other_images)
                    });
                }
            })
        } else {
            let other_images = this.state.other_images;
            other_images.splice(index, 1);
            this.setState({ other_images: other_images }, () => {
                console.log(this.state.other_images)
            });
        }
    }

    handleMoreImages() {
        const parsed = queryString.parseUrl(this.state.next_url);
        this.props.axios.get('byo-image/', { params: parsed.query }).then((res) => {
            if (res.data.status.code === 200) {
                var new_images = this.state.other_images;
                var images = [...new_images, ...res.data.data]
                this.setState({
                    other_images: images,
                    next_url: res.data.next
                })
            }
        })
    }

    updateBYO(e) {
        e.preventDefault();
        this.updateTime();
    }

    updateBYODeatil() {
        // e.preventDefault();
        var formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("city", this.state.city);
        formData.append("state", this.state.state.value);
        formData.append("country", this.state.country);
        formData.append("address", this.state.address);
        formData.append("phone", this.state.phone);
        formData.append("description", this.state.description);
        let cuisine = []
        if (this.state.selected_cuisine && this.state.selected_cuisine.length > 0) {
            this.state.selected_cuisine.map(function (i) {
                cuisine.push(i.value)
                return i;
            })
        }
        let facility = []
        if (this.state.selected_facility && this.state.selected_facility.length > 0) {
            this.state.selected_facility.map(function (i) {
                facility.push(i.value)
                return i;
            })
        }
        let payment_type = []
        if (this.state.selected_payment_type && this.state.selected_payment_type.length > 0) {
            this.state.selected_payment_type.map(function (i) {
                payment_type.push(i.id)
                return i;
            })
        }
        formData.append('payment_types', JSON.stringify(payment_type))
        if (this.state.web_url) {
            formData.append('website', this.addhttp(this.state.web_url))
        } else {
            formData.append('website', this.state.web_url)
        }
        formData.append('email', this.state.email)

        formData.append('cuisines', JSON.stringify(cuisine))
        formData.append('facilities', JSON.stringify(facility))
        formData.append("entry_fee", this.state.entry_fee ? parseFloat(this.state.entry_fee).toFixed(2) : "");
        formData.append("corkage_fee", this.state.corkage_fee);
        // formData.append("corkage_fee", "asdasdasd");
        formData.append("zipcode", this.state.zipcode)
        formData.append("sitting_capacity", this.state.sitting_capacity);
        formData.append("location", JSON.stringify(this.state.location));

        if (this.state.image_changed) {
            if (this.state.logo_boundaries.length > 0) {
                formData.append("logo", this.state.logo_file)
                formData.append("logo_boundary", this.state.logo_boundaries.join(','))

            } else {
                formData.append("logo", this.state.logo)
            }
        }
        if (this.state.cover_image_changed) {
            if (this.state.cover_image_boundaries.length > 0) {
                formData.append("cover_image", this.state.cover_image_file)
                formData.append("cover_image_boundary", this.state.cover_image_boundaries.join(','))
            } else {
                formData.append("cover_image", this.state.cover_image)
            }
        }

        this.props.axios.put(`byo/${this.props.match.params.id}/`, formData).then((res) => {
            if (res.data.status.code === 200) {
                this.setState({
                    form_Incomplete: false,
                    name: "",
                    phone: "",
                    address: "",
                    country: "",
                    state: "",
                    city: "",
                    selected_cuisine: "",
                    logo: "",
                    cover_image: "",
                    entry_fee: "",
                    corkage_fee: "",
                    image_changed: false,
                    cover_image_changed: false,
                    logo_boundaries: [],
                    cover_image_boundaries: [],
                    logo_file: '',
                    cover_image_file: '',
                    image_object: "",
                    cover_image_object: "",
                    description: "",
                    step: 4,
                }, () => {
                    this.props.history.push("/byo-detail/" + this.props.match.params.id);
                })
            }
            else if (res.data.status.code === 403) {
                if (res.data.status.detail) {
                    let error = [];
                    for (let key in res.data.status.detail) {
                        if (res.data.status.detail.hasOwnProperty(key)) {
                            error.push(`${(key.split("_")).join(" ")} : ${res.data.status.detail[key][0]}`);
                        }
                    }
                    this.setState({ detail_error: error }, () => {
                        (function () {
                            window.scrollTo(0, 0)
                        })()
                    })
                }
            }
        })
    }

    render() {
        // console.error(this.state.byotiming_set)

        return (
            <Layout {...this.props} layout="shrink">
                <Helmet>
                    <title>{this.props.match.params.type === "add" ? "Create BYO" : "Edit BYO"} </title>

                </Helmet>
                <Prompt
                    when={(this.state.step === 4) ? false : true}
                    message={this.props.stringFile('leave_page', false)}
                />
                <div className="add_byo">
                    <div className="claim_head">
                        <div className="container">
                            <h1 className="heading_with_back">
                                {/* {this.state.step !== 1 && */}
                                <span className="back_button" onClick={() => { this.state.step === 1 || this.state.step === 4 ? this.props.history.goBack() : this.setState({ step: this.state.step - 1 }) }}>
                                    <img src="/images/back.svg" alt="Go back" />
                                </span>
                                {/* } */}
                                {this.props.match.params.type === "edit" ? " Edit BYO" : " Create New BYO"}
                                <ul className="claim_step_cont">
                                    <li className="claim_step_indicator active"></li>
                                    <li className={this.state.step > 1 ? "claim_step_indicator active" : "claim_step_indicator"}></li>
                                    <li className={this.state.step > 2 ? "claim_step_indicator active" : "claim_step_indicator"}></li>
                                </ul>
                            </h1>
                        </div>
                    </div>
                    <div className="form_add_edit_byo">
                        {this.state.step === 1 &&
                            <form onSubmit={this.handleFirstStep} noValidate={true} id="first_step_form">
                                <div className="container">
                                    {/* <div className="row">
                                        <div className="col-12">
                                            <div className="form-group custom_error byo_group">
                                                <div className="custom_toggle">
                                                    <input type="checkbox" id={"status_checkbox"} className="custom_toggle" onChange={(e) => { this.setState({ search_type: e.target.checked }) }} checked={this.state.search_type} />
                                                    <label htmlFor="status_checkbox">Create BYO from Google Places</label>
                                                    <label className="make_toggle" htmlFor="status_checkbox"><span></span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label" htmlFor="name">BYO Name<span className="mandatory_astrick">*</span></label>
                                                <div>
                                                    <input type="text" name="name" id="name" value={this.state.name} maxLength={100} onChange={(e) => { this.setState({ name: e.target.value }) }} required />
                                                    <div id="name_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label">Phone Number<span className="mandatory_astrick">*</span></label>
                                                <div>
                                                    <PhoneInput
                                                        placeholder=""
                                                        country='AU'
                                                        value={this.state.phone}
                                                        onChange={value => this.setState({ phone: value })}
                                                        required={true}
                                                        id="phone"
                                                    />
                                                </div>
                                                <div id="phone_error" className="help-block has-error"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group custom_error byo_group">
                                                <div id="google_place_search"></div>
                                                <label className="form_label" htmlFor="address">BYO Address<span className="mandatory_astrick">*</span></label>
                                                {/* <div>
                                                    <input type="text" />
                                                </div> */}
                                                <Geosuggest
                                                    id="address"
                                                    ref={(el) => this.geosuggest = el}
                                                    placeholder={this.state.search_type ? "Seach Google Places" : "Address"}
                                                    initialValue={this.state.address}
                                                    location={google ? new google.maps.LatLng(-25.274398, 133.77513599999997) : ""}
                                                    highlightMatch={true}
                                                    country={['au']}
                                                    radius="50"
                                                    onSuggestSelect={this.onSuggestSelect.bind(this)}
                                                    onSuggestNoResults={this.onSuggestNoResults.bind(this)}
                                                    required={true}
                                                    autoComplete="off"
                                                />
                                                <div id="address_error" className="help-block has-error"></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 ">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label" htmlFor="web_url">Website</label>
                                                <div>
                                                    <input type="url" name="web_url" id="web_url" value={this.state.web_url} maxLength={100} onChange={(e) => { this.setState({ web_url: e.target.value }) }} title="Please enter valid url. Eg: http://example.com" />
                                                    <p className="minor_hint">Eg: http://example.com</p>
                                                    <div id="web_url_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 ">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label" htmlFor="description">BYO Description<span className="mandatory_astrick">*</span></label>
                                                <div>
                                                    <textarea type="text" name="description" id="description" value={this.state.description} maxLength={250} onChange={(e) => { this.setState({ description: e.target.value }) }} required />
                                                    <div id="description_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label" htmlFor="email">Email Address<span className="mandatory_astrick">*</span></label>
                                                <div>
                                                    <input type="email" name="email" id="email" value={this.state.email} maxLength={100} onChange={(e) => { this.setState({ email: e.target.value }) }} required />
                                                    <div id="email_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label" htmlFor="zipcode">Postcode<span className="mandatory_astrick">*</span></label>
                                                <div>
                                                    <input type="number" id="zipcode" minLength={4} maxLength={6} data-error={this.state.zipcode ? "this field required minimum 4 or maximum 6 digits" : "this field is required"} value={this.state.zipcode} onChange={(e) => { this.setState({ zipcode: e.target.value }) }} required />
                                                    <div id="zipcode_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-12 col-lg-6">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label" htmlFor="country">Country<i title="Country will be automatically selected after the choose address from the dropdown list" className="material-icons hint_country">help</i><span className="mandatory_astrick">*</span></label>
                                                <div>
                                                    <input type="text" id="country" value={this.state.country} onChange={(e) => { this.setState({ country: e.target.value }) }} required readOnly />
                                                    <div id="country_error" className="help-block has-error"></div>
                                                </div>
                                                <p className="country_p_hint">Country will be automatically selected after the choose address from the dropdown list</p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label" htmlFor="state">State<span className="mandatory_astrick">*</span></label>
                                                <div>
                                                    {/* <select id="state" value={this.state.state} onChange={(e) => { this.setState({ state: e.target.value }) }} className="custom__value-container custom__value-container--is-multi custom__value-container--has-value css-1hwfws3" required>
                                                        <option value=''>Select</option>
                                                        {this.state.state_options.length > 0 &&
                                                            this.state.state_options.map((i, idx) => {
                                                                return <option value={i} key={'stateOpt_' + idx}>{i}</option>
                                                            })
                                                        }
                                                    </select> */}
                                                    <Select
                                                        id="state"
                                                        value={this.state.state}
                                                        onChange={this.handleState.bind(this)}
                                                        placeholder=""
                                                        isRequired={true}
                                                        hasValue={true}
                                                        isMulti={false}
                                                        // blurInputOnSelect={false}
                                                        classNamePrefix={"custom"}
                                                        // closeMenuOnSelect={false}
                                                        options={this.state.state_options}
                                                    />
                                                    <div id="state_error" className="help-block has-error"></div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-12 col-lg-6">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label" htmlFor="city">City<span className="mandatory_astrick">*</span></label>
                                                <div>
                                                    <input type="text" id="city" value={this.state.city} onChange={(e) => { this.setState({ city: e.target.value }) }} required />
                                                    <div id="city_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group custom_error byo_group">
                                                <label className="form_label" htmlFor="sitting_capacity">Sitting Capacity</label>
                                                <div>
                                                    <input type="number" id="sitting_capacity" value={this.state.sitting_capacity} max={2000} onChange={(e) => { this.setState({ sitting_capacity: e.target.value }) }} />
                                                    <div id="sitting_capacity_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">

                                        {/* <div className="col-12 col-lg-6">
                                            <div className="form-group byo_group">
                                                <label className="form_label" htmlFor="entry_fee">Entry Fee</label>
                                                <div>
                                                    <input type="number" id="entry_fee" value={this.state.entry_fee} step="0.01" onChange={(e) => { this.setState({ entry_fee: e.target.value }) }} />
                                                    <div id="entryfee_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group byo_group">
                                                <label className="form_label" htmlFor="corkage_fee">Corkage Fee</label>
                                                <div>
                                                    <input type="text" id="corkage_fee" value={this.state.corkage_fee} onChange={(e) => { this.setState({ corkage_fee: e.target.value }) }} />
                                                    <div id="corkage_fee_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className={this.state.is_map_dragging === "true" ? "draggable_map_marker" : ""}>
                                                <MyMapComponent
                                                    isMarkerShown={true}
                                                    lat={this.state.location.latitude}
                                                    lng={this.state.location.longitude}
                                                    address={this.state.address}
                                                    is_map_dragging={this.state.is_map_dragging}
                                                    handleOverlayComplete={this.onOverlayComplete.bind(this)}
                                                    getAddress={this.getAddress.bind(this)}
                                                    getLatLng={this.getLatLng.bind(this)}
                                                    check_drag={this.is_map_dragging.bind(this)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button_positioning">
                                        <button type="submit" className="primary_button">Continue</button>
                                    </div>
                                </div>
                            </form>
                        }
                        {this.state.step === 2 &&
                            <form onSubmit={this.handleSecondStep} id="second_step_form"  >
                                <div className="container">
                                    {/* <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group byo_group">
                                                <label className="form_label" htmlFor="entry_fee">Entry Fee</label>
                                                <div>
                                                    <input type="number" id="entry_fee" value={this.state.entry_fee} step="0.01" onChange={(e) => { this.setState({ entry_fee: e.target.value }) }} />
                                                    <div id="entryfee_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="form-group byo_group">
                                                <label className="form_label">ABN Number</label>
                                                <div>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group byo_group">
                                                <label className="form_label" htmlFor="cuisines">Add Cuisine<span className="mandatory_astrick">*</span></label>
                                                <div>
                                                    <Select
                                                        id="cuisines"
                                                        value={this.state.selected_cuisine}
                                                        onChange={this.handleCuisine.bind(this)}
                                                        placeholder=""
                                                        isRequired={true}
                                                        hasValue={true}
                                                        isMulti={true}
                                                        blurInputOnSelect={false}
                                                        classNamePrefix={"custom"}
                                                        closeMenuOnSelect={false}
                                                        options={this.state.cuisines_list}
                                                    />
                                                    <div id="cuisines_error" className="help-block has-error"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group byo_group">
                                                <label className="form_label" htmlFor="facility">Facilities</label>
                                                <div>
                                                    <Select
                                                        id="facility"
                                                        value={this.state.selected_facility}
                                                        onChange={this.handleFacility.bind(this)}
                                                        placeholder=""
                                                        isRequired={false}
                                                        hasValue={true}
                                                        isMulti={true}
                                                        blurInputOnSelect={false}
                                                        classNamePrefix={"custom"}
                                                        closeMenuOnSelect={false}
                                                        options={this.state.facility_list}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group byo_group">
                                                <label className="form_label" htmlFor="payment_type">Payment Types</label>
                                                <div>
                                                    <Select
                                                        id="payment_type"
                                                        value={this.state.selected_payment_type}
                                                        onChange={(option) => this.setState({ selected_payment_type: option })}
                                                        placeholder=""
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        isRequired={false}
                                                        hasValue={true}
                                                        isMulti={true}
                                                        blurInputOnSelect={false}
                                                        classNamePrefix={"custom"}
                                                        closeMenuOnSelect={false}
                                                        options={this.state.payment_list}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group byo_group">
                                                <label className="form_label">Service Timing</label>
                                                <p className="timings_hint">Time is in 24 hours format</p>
                                                {this.state.byotiming_set.map((i, index) => {

                                                    return (<div className="single_service_day" key={index} >
                                                        <h4>
                                                            <span className="day_name">{i.day_name}</span>
                                                            <input type="checkbox" id={"status_checkbox_" + i.day} className="custom_toggle" onChange={this.handleTimingStatus.bind(this, i, index)} checked={i.is_checked} />
                                                            <label className="make_toggle" htmlFor={"status_checkbox_" + i.day}><span></span></label>
                                                            {i.is_checked &&
                                                                <div className="timing_inputs">
                                                                    {/* <input type="time" id={"from_time_" + i.day} value={i.from_time} onChange={this.handleTimings.bind(this, index, "from")} required /> */}
                                                                    <TimePicker
                                                                        showSecond={false}
                                                                        value={i.from_time}
                                                                        className="xxx"
                                                                        onChange={this.handleTimings.bind(this, index, "from")}
                                                                        // format={format}
                                                                        isRequired
                                                                    />
                                                                    <span>To</span>
                                                                    <TimePicker
                                                                        showSecond={false}
                                                                        value={i.to_time}
                                                                        className="xxx"
                                                                        id={"to_time_" + i.day}
                                                                        onChange={this.handleTimings.bind(this, index, "to")}
                                                                    // format={format}
                                                                    // inputReadOnly
                                                                    />
                                                                    {/* <input type="time" id={"to_time_" + i.day} value={i.to_time} onChange={this.handleTimings.bind(this, index, "to")} required /> */}
                                                                    {/* {this.props.match.params.type === "edit" &&
                                                                        <React.Fragment>
                                                                            {i.is_show_tick && <button type="button" className="save_time" title="Update Time" id={"time_update_" + index} onClick={this.updateTime.bind(this, i.id, i.day)}>Save</button>}
                                                                            {!i.is_new &&
                                                                                <i title="Remove Time" className="mdi mdi-close remove_hours" onClick={this.removeTime.bind(this, i.id, i.day)}></i>
                                                                            }
                                                                        </React.Fragment>
                                                                    } */}
                                                                </div>
                                                            }
                                                        </h4>
                                                    </div>)
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button_positioning">
                                        <button type="submit" className="primary_button">{this.props.match.params.type === "edit" ? "Continue" : "Continue"}</button>
                                    </div>
                                </div>
                            </form>
                        }
                        {(this.state.step === 3 && this.props.match.params.type !== "edit") &&
                            <Fragment>
                                <div id="detail_error" className="detail_error" >
                                    {this.state.detail_error.map((i, index) => {
                                        return (
                                            <p key={`error_${index}`}>{i}</p>
                                        )
                                    })

                                    }

                                </div>
                                <form className="verification_from" id="verification_from" onSubmit={this.submitDoc.bind(this)} noValidate={true}>
                                    <div className="container">
                                        <div className="row">
                                            {/* <div className="col-12 col-lg-6">
                                                <div className="form__group form-group">
                                                    <label className="form_label" htmlFor="id_proof">Upload ID Proof<span className="mandatory_astrick">*</span></label>
                                                    <div>
                                                        <label className="file_wrap" htmlFor="id_proof">
                                                            <img src="/images/add.svg" alt="" /> Upload
                                                            <input id="id_proof" type="file" accept=".jpeg , .jpg , .png , .pdf" onChange={(e) => { this.handleUploadDocument("id_proof", e) }} />
                                                        </label>
                                                        <div>
                                                            {this.state.id_proof &&
                                                                <label className="single_dic_select">
                                                                    <span>{this.state.id_proof.name.length > 50 ? this.state.id_proof.name.slice(0, 35) + "..." : this.state.id_proof.name}<i className="material-icons" onClick={(e) => { this.setState({ id_proof: "" }) }}>close</i></span>
                                                                </label>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group custom_error byo_group">
                                                    <label className="form_label">ABN Number<span className="mandatory_astrick">*</span></label>
                                                    <div>
                                                        <input type="number" name="abn" id="abn" value={this.state.abn} minLength={11} maxLength={11} data-error={this.state.abn.length === 11 ? "" : "this field required minimum & maximum 11 characters"} onChange={(e) => this.setState({ abn: e.target.value.length > 11 ? this.state.abn : e.target.value })} required />
                                                        <div id="abn_error" className="help-block with-errors has-error"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form__group form-group">
                                                    <label className="form_label" style={{fontWeight : 700}} htmlFor="reg_certificate">Business Registration Certificate<span className="mandatory_astrick">*</span></label>
                                                    <div>
                                                        <label className="file_wrap" htmlFor="reg_certificate">
                                                            <img src="/images/add.svg" alt="" /> Upload
                                                            <input id="reg_certificate" type="file" accept=".jpeg , .jpg , .png , .pdf" onChange={(e) => { this.handleUploadDocument("reg_certificate", e) }} />
                                                        </label>
                                                        <div>
                                                            {this.state.reg_certificate &&
                                                                <label className="single_dic_select">
                                                                    <span>{this.state.reg_certificate.name.length > 50 ? this.state.reg_certificate.name.slice(0, 35) + "..." : this.state.reg_certificate.name}<i className="material-icons" onClick={(e) => { this.setState({ reg_certificate: "" }) }}>close</i></span>
                                                                </label>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="form__group form-group">
                                                    <label className="form_label" htmlFor="other_doc">Upload Other Relevant Documents</label>
                                                    <div>
                                                        <label className="file_wrap" htmlFor="other_doc">
                                                            <img src="/images/add.svg" alt="" /> Upload
                                                            <input id="other_doc" type="file" accept=".jpeg , .jpg , .png , .pdf" onChange={(e) => { this.handleUploadDocument("other_doc", e) }} />
                                                        </label>
                                                        <div>
                                                            {this.state.other_certificate &&
                                                                <label className="single_dic_select">
                                                                    <span>{this.state.other_certificate.name.length > 50 ? this.state.other_certificate.name.slice(0, 35) + "..." : this.state.other_certificate.name}<i className="material-icons" onClick={(e) => { this.setState({ other_certificate: "" }) }}>close</i></span>
                                                                </label>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="button_positioning">
                                            <button type="submit" className="primary_button">Create BYO</button>
                                        </div>
                                    </div>
                                </form>
                                {/* <form className="verification_from" onSubmit={this.submitDoc} action="">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="form__group form-group">
                                                    <label className="form_label" htmlFor="user_image_12">Upload Logo</label>
                                                    <div>
                                                        <label className="file_wrap" htmlFor="user_image_12">
                                                            <img src="/images/add.svg" alt="" /> Upload
                                                            <input id="user_image_12" type="file" name="userImage" accept="image/png, image/jpeg , image/jpeg" onChange={this.handleprofileobject.bind(this)} hidden />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form__group form-group">
                                                    <label className="form_label" htmlFor="user_image_13">Upload Cover Image</label>
                                                    <div>
                                                        <label className="file_wrap" htmlFor="user_image_13">
                                                            <img src="/images/add.svg" alt="" /> Upload
                                                            <input id="user_image_13" type="file" name="userImage1" accept="image/png, image/jpeg , image/jpeg" onChange={this.handleCoverObject.bind(this)} hidden />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="form__group form-group">
                                                    <label className="form_label" htmlFor="other_images">Upload Other Images</label>
                                                    <div>
                                                        <label className="file_wrap" htmlFor="other_images">
                                                            <img src="/images/add.svg" alt="" /> Upload
                                                            <input type="file" accept="image/*" name="other_images" id="other_images" onChange={this.handleOtherImage.bind(this)} multiple hidden />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button_positioning">
                                            <button type="submit" className="primary_button">Create BYO</button>
                                        </div>
                                    </div>
                                </form> */}
                            </Fragment>
                        }
                        {/* <div id="detail_error" className="detail_error" >
                            {this.state.detail_error.map((i, index) => {
                                return (
                                    <p key={`error_${index}`}>{i}</p>
                                )
                            })

                            }

                        </div> */}
                        {(this.state.step === 3 && this.props.match.params.type === "edit") &&
                            <Fragment>
                                <div id="detail_error" className="detail_error" >
                                    {this.state.detail_error.map((i, index) => {
                                        return (
                                            <p key={`error_${index}`}>{i}</p>
                                        )
                                    })

                                    }

                                </div>
                                <form className="verification_from" onSubmit={this.updateBYO.bind(this)} action="">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="form__group form-group">
                                                    <label className="form_label" htmlFor="user_image_12">Upload Logo<span className="mandatory_astrick">*</span></label>
                                                    <div>
                                                        <label className="file_wrap" htmlFor="user_image_12">
                                                            <img src="/images/add.svg" alt="" /> Upload
                                                            <input id="user_image_12" type="file" name="userImage" accept="image/png, image/jpeg , image/jpeg" onChange={this.handleprofileobject.bind(this)} hidden />
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <br /> */}
                                                <div>
                                                    <div className="photo_cards">
                                                        <label htmlFor="user_image_12">
                                                            <img id={"image"} src={this.state.logo ? this.state.logo : "/assets/images/choose_logo.svg"} height="200px" alt="" />
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form__group form-group">
                                                    <label className="form_label" htmlFor="user_image_13">Upload Cover Image<span className="mandatory_astrick">*</span></label>
                                                    <div>
                                                        <label className="file_wrap" htmlFor="user_image_13">
                                                            <img src="/images/add.svg" alt="" /> Upload
                                                            <input id="user_image_13" type="file" name="userImage1" accept="image/png, image/jpeg , image/jpeg" onChange={this.handleCoverObject.bind(this)} hidden />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="photo_cards">
                                                        <label htmlFor="user_image_13">
                                                            <img id={"image"} src={this.state.cover_image ? this.state.cover_image : "/assets/images/choose_logo.svg"} height="200px" alt="" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="form__group form-group">
                                                    <label className="form_label" htmlFor="other_images">Upload Other Images</label>
                                                    <div>
                                                        <label className="file_wrap" htmlFor="other_images">
                                                            <img src="/images/add.svg" alt="" /> Upload
                                                            <input type="file" accept="image/*" name="other_images" id="other_images" onChange={this.handleOtherImage.bind(this)} multiple hidden />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <div className="image_container">
                                                    {this.state.other_images.length > 0 &&
                                                        <React.Fragment>
                                                            {this.state.other_images.map((i, index) => {
                                                                return (<div className="each_image" id={"other_image" + index} key={index}>
                                                                    <img src={i.image} alt="" />
                                                                    <button type="button" className="remove_images" onClick={this.handleRemoveImage.bind(this, i.id, index)}>
                                                                        <i title="Remove Image" className="material-icons">close</i>
                                                                    </button>
                                                                </div>)
                                                            }, this)
                                                            }
                                                            {this.state.next_url &&
                                                                <div className="each">
                                                                    <button type="button" className="view_m_images" onClick={this.handleMoreImages.bind(this)}>View More Images</button>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button_positioning">
                                            <button type="submit" className="primary_button">Update BYO</button>
                                        </div>
                                    </div>
                                </form>
                            </Fragment>
                        }
                    </div>
                    <ImageUpload onimageupload={this.handleprofile.bind(this)} profile_picture={this.state.logo} image_object={this.state.image_object} aspect_ratio={1 / 1} {...this.props} />
                    <ImageUpload onimageupload={this.handleprofileImage.bind(this)} profile_picture={this.state.cover_image} modal_id="modalCrop" image_object={this.state.cover_image_object} type="cover" aspect_ratio={16 / 9} {...this.props} />
                    {this.state.step === 4 &&
                        <div className="success_submit_box">
                            <img src="/images/check.svg" alt="" />
                            <Fragment>
                                <h2>BYO Created!</h2>
                                <p>Your BYO <b>{this.state.name}</b> was created successfully and is under review, you can see status in <Link to="/profile#requested_byo"><b>My Account</b></Link></p>
                            </Fragment>
                        </div>
                    }
                </div>
            </Layout>
        )
    }
}