import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import Layout from "../../layout";
import './claim.scss'
import Helmet from 'react-helmet';
// import Countdown from 'react-countdown-now';
import { validation } from "../../global/custom_validator";

export default class ClaimBYO extends Component {
  state = {
    haveRegisteredNumber: true,
    step: 1,
    byo_detail: "",
    other_certificate: "",
    id_proof: "",
    reg_certificate: "",
    abn: "",
    code: "",
    detail_error: [],
    calledOn: "",
  }

  componentDidMount() {
    if (this.props.match.params.id && window.localStorage.getItem('byo')) {
      // this.getByoDetail();
      var parsed = JSON.parse(window.localStorage.getItem('byo'));
      if (parsed.phone) {
        this.getNumber();
        this.setState({ byo_detail: parsed, step: 1 })
      } else {
        this.setState({ byo_detail: parsed, step: 2 })
      }
    } else {
      this.getByoDetail();
    }
    (function () {
      window.scrollTo(0, 0)
    })()
    console.error(this.props)
  }

  componentWillUnmount() {
    window.localStorage.clear();
  }

  submitDoc(e) {
    e.preventDefault();
    if (validation('verification_from')) {
      if (this.state.id_proof && this.state.reg_certificate) {
        let formData = new FormData();
        formData.append("id_proof", this.state.id_proof);
        formData.append("byo_registration_copy", this.state.reg_certificate);
        formData.append("other_document", this.state.other_certificate);
        formData.append("abn", this.state.abn);
        formData.append("b_y_o", this.props.match.params.id);
        this.props.axios.post(`byo-claim/`, formData).then(res => {
          if (res.data.status.code === 200) {
            this.setState({
              step: 4,
              other_certificate: "",
              id_proof: "",
              reg_certificate: "",
              abn: "",
              detail_error: []
            })
          }
          else if (res.data.status.code === 403) {
            if (res.data.status.detail) {
              let error = [];
              for (let key in res.data.status.detail) {
                if (res.data.status.detail.hasOwnProperty(key)) {
                  console.log(res.data.status.detail[key])
                  error.push(`${key} : ${res.data.status.detail[key]}`);
                }
              }
              this.setState({ detail_error: error }, () => {
                (function () {
                  window.scrollTo(0, 0)
                })()
              })
            }
          }
        })

      } else {
        this.props.stringFile('required_doc');
      }

    }
  }

  getByoDetail = () => {
    this.props.axios.get(`byo/${this.props.match.params.id}/`).then((res) => {
      if (res.data.status.code === 200) {
        if (res.data.data.phone) {
          this.setState({ byo_detail: res.data.data }, () => {
            this.getNumber();
          })
        } else {
          this.setState({ byo_detail: res.data.data, step: 2 })
        }
      }
    })
  }

  getNumber() {
    this.props.axios.get(`claim-number/`).then(res => {
      if (res.data.status.code === 200) {
        this.setState({ step: 1, calledOn: res.data.data })
      }
    })
  }

  handleUploadDocument = (type, e) => {

    let file = e.target.files[0];
    if (file.type) {
      if (file.type.toLowerCase() === "image/jpeg" || file.type.toLowerCase() === "image/jpg" || file.type.toLowerCase() === "image/png" || file.type.toLowerCase() === "application/pdf") {
        if (((file.size / 1024) / 1024).toFixed(4) > 10) {
          this.props.stringFile('file_size')
        } else {
          if (type === "id_proof")
            this.setState({ id_proof: file });
          else if (type === "reg_certificate")
            this.setState({ reg_certificate: file });
          else
            this.setState({ other_certificate: file });
        }
      } else {
        this.props.stringFile('accept_files');
      }
    }
    document.getElementById(`${type}`).value = "";
  }

  handleMobileVerificaton = (e) => {
    e.preventDefault();
    if (validation('mobile_form')) {
      let formData = new FormData();
      formData.append('b_y_o', this.props.match.params.id);
      formData.append('code', this.state.code)
      this.props.axios.post(`byo-claim-code/`, formData).then(res => {
        if (res.data.status.code === 200) {
          if (this.props.state.header_state) {
            let result = this.props.state.header_state;
            let index = result.search_result.data.findIndex(x => x.id === this.props.match.params.id)
            if (index > -1) {
              result.search_result.data[index].is_claimed = true;
              this.props.handleSearch(result);
            }
          }
          this.setState({
            step: 3,
            code: ""
          }, () => {
            (function () {
              window.scrollTo(0, 0)
            })()
          })
        }
      })
    }
  }

  handleBack = () => {
    if (this.state.byo_detail.phone) {
      if (this.state.step === 2) {
        this.setState({ step: 1 })
      } else {
        if (this.state.step === 3) {
          this.props.history.goBack();
        } else {
          if (window.confirm('Are you sure you want to leave this page?')) {
            this.props.history.goBack();
          }
        }
      }
    }
  }

  render() {
    return (
      <Layout {...this.props} layout="shrink">
        <Helmet>
          <title>Claim BYO | {this.state.byo_detail ? this.state.byo_detail.name : ""}</title>
          <meta name="description" content={this.state.byo_detail ? this.state.byo_detail.description : ""} />
        </Helmet>
        <div className="clain_byo_page">
          <div className="claim_head">
            <div className="container">
              <h1 className="heading_with_back">
                <span className="back_button" onClick={this.handleBack}><img src="/images/back.svg" alt="Go back to homepage" /></span>  Claim your  BYO
                <ul className="claim_step_cont">
                  <li className="claim_step_indicator active"></li>
                  <li className={this.state.step > 1 ? "claim_step_indicator active" : "claim_step_indicator"}></li>
                  {!this.state.haveRegisteredNumber &&
                    <li className={this.state.step > 2 ? "claim_step_indicator active" : "claim_step_indicator"}></li>
                  }
                </ul>
              </h1>
            </div>
          </div>
          <div className="container">
            {(this.state.step === 1 && this.state.byo_detail) &&
              <Fragment>
                <form onSubmit={this.handleMobileVerificaton} noValidate={true} id="mobile_form">
                  <div className="claim_byo_info">
                    <div className="row">
                      <div className="col-12 col-lg-8">
                        <h2 className="claim_name">{this.state.byo_detail.name}</h2>
                        <p className="claim_addr">{this.state.byo_detail.address}</p>
                        {/* <p className="clain_email">{this.state.byo_detail.email}</p> */}
                      </div>
                      <div className="col-12 col-lg-4">
                        <h4>Registered Number</h4>
                        <div className="phone_self">
                          <img src="/images/phone.svg" alt="" /> {this.state.byo_detail.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="twilio_box">
                    <p className="twilio_instruction">Call the number below on your registered restaurant number <b>{this.state.byo_detail.phone}</b> to receive your verification code and complete the verification process</p>
                    <div className="twillio_receive_call">
                      <div className="number_calling">
                        <img src="/images/call.svg" alt="" /> <a href={`tel:${this.state.calledOn}`}>{this.state.calledOn}</a>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group custom_error byo_group">
                            <label className="form_label">Verification Code<span className="mandatory_astrick">*</span></label>
                            <div>
                              <input type="number" name="code" id="code" value={this.state.code} minLength={6} style={{ textAlign: "center", backgroundColor: "#fff" }} maxLength={6} data-error={this.state.code.length === 6 ? "" : "this field required minimum & maximum 6 characters"} onChange={(e) => this.setState({ code: e.target.value.length > 6 ? this.state.code : e.target.value })} required />
                              <div id="code_error" className="help-block with-errors has-error"></div>
                            </div>
                          </div>
                        </div>
                        <p><b>Note :- </b>Verification code will be valid for next 5 minutes.</p>
                      </div>
                      {/* <div className="progress_indicator">
                      <span style={{ right: "50%" }}></span>
                    </div>
                    <span id="timer" className="time_left"> <Countdown date={Date.now() + 300000} renderer={renderer} /></span> */}
                    </div>
                    <div style={{ textAlign: "center" }} className="button_positioning">
                      <div>
                        <button type="submit" className="primary_button">Submit</button>
                      </div>
                      <button onClick={() => {
                        this.setState({ haveRegisteredNumber: false, step: 2 }, () => {
                          (function () {
                            window.scrollTo(0, 0)
                          })()
                        })
                      }} type="button" className="link_button">Don't have access to this Number?</button>
                    </div>
                  </div>
                </form>
              </Fragment>
            }
            {this.state.step === 2 &&
              <Fragment>
                <div id="detail_error" className="detail_error" >
                  {this.state.detail_error.map((i, index) => {
                    return (
                      <p key={`error_${index}`}>{i}</p>
                    )
                  })

                  }

                </div>
                <form className="verification_from" id="verification_from" onSubmit={this.submitDoc.bind(this)} noValidate={true}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form__group form-group">
                          <label className="form_label" htmlFor="id_proof" style={{fontWeight : 700}}>Upload ID Proof<span className="mandatory_astrick">*</span></label>
                          <div>
                            <label className="file_wrap" htmlFor="id_proof">
                              <img src="/images/add.svg" alt="" /> Upload
                                <input id="id_proof" type="file" accept=".jpeg , .jpg , .png , .pdf" onChange={(e) => { this.handleUploadDocument("id_proof", e) }} />
                            </label>
                            <div>
                              {this.state.id_proof &&
                                <label className="single_dic_select">
                                  <span>{this.state.id_proof.name.length > 50 ? this.state.id_proof.name.slice(0, 35) + "..." : this.state.id_proof.name}<i className="material-icons" onClick={(e) => { this.setState({ id_proof: "" }) }}>close</i></span>
                                </label>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form__group form-group">
                          <label className="form_label" style={{fontWeight : 700}} htmlFor="reg_certificate">BYO Registration Certificate<span className="mandatory_astrick">*</span></label>
                          <div>
                            <label className="file_wrap" htmlFor="reg_certificate">
                              <img src="/images/add.svg" alt="" /> Upload
                              <input id="reg_certificate" type="file" accept=".jpeg , .jpg , .png , .pdf" onChange={(e) => { this.handleUploadDocument("reg_certificate", e) }} />
                            </label>
                            <div>
                              {this.state.reg_certificate &&
                                <label className="single_dic_select">
                                  <span> {this.state.reg_certificate.name.length > 50 ? this.state.reg_certificate.name.slice(0, 35) + "..." : this.state.reg_certificate.name}<i className="material-icons" onClick={(e) => { this.setState({ reg_certificate: "" }) }}>close</i></span>
                                </label>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="form__group form-group">
                          <label className="form_label" htmlFor="other_doc" style={{fontWeight : 700}}>Upload Other Relevant Documents</label>
                          <div>
                            <label className="file_wrap" htmlFor="other_doc">
                              <img src="/images/add.svg" alt="" /> Upload
                                <input id="other_doc" type="file" accept=".jpeg , .jpg , .png , .pdf" onChange={(e) => { this.handleUploadDocument("other_doc", e) }} />
                            </label>
                            <div>
                              {this.state.other_certificate &&
                                <label className="single_dic_select">
                                  <span>{this.state.other_certificate.name.length > 50 ? this.state.other_certificate.name.slice(0, 35) + "..." : this.state.other_certificate.name}<i className="material-icons" onClick={(e) => { this.setState({ other_certificate: "" }) }}>close</i></span>
                                </label>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group custom_error byo_group">
                          <label className="form_label">ABN Number<span className="mandatory_astrick">*</span></label>
                          <div>
                            <input type="number" name="abn" id="abn" value={this.state.abn} minLength={11} maxLength={11} data-error={this.state.abn.length === 11 ? "" : "this field required minimum & maximum 11 characters"} onChange={(e) => this.setState({ abn: e.target.value.length > 11 ? this.state.abn : e.target.value })} required />
                            <div id="abn_error" className="help-block with-errors has-error"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button_positioning">
                      <button type="submit" className="primary_button">Send Claim Request</button>
                    </div>
                  </div>
                </form>
                {/* <form className="verification_from" onSubmit={this.submitDoc} action="">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="form__group form-group">
                        <label className="form_label" htmlFor="id_proof">Upload ID Proof</label>
                        <div>
                          <label className="file_wrap" htmlFor="id_proof">
                            <img src="/images/add.svg" alt=""/> Upload
                            <input id="id_proof" type="file" required/>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="form__group form-group">
                        <label className="form_label" htmlFor="reg_cert">BYO registration Certificate</label>
                        <div>
                          <label className="file_wrap" htmlFor="reg_cert">
                            <img src="/images/add.svg" alt=""/> Upload
                            <input id="reg_cert" type="file" required/>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="form__group form-group">
                        <label className="form_label" htmlFor="other_docs">Upload other relevant documents</label>
                        <div>
                          <label className="file_wrap" htmlFor="other_docs">
                            <img src="/images/add.svg" alt="" /> Upload
                            <input id="other_docs" type="file" required/>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button_positioning">
                    <button type="submit" className="primary_button">Continue</button>
                  </div>
                </form> */}
              </Fragment>
            }
            {(this.state.step > 2) &&
              <Fragment>
                <div className="success_submit_box">
                  <img src="/images/check.svg" alt="" />
                  {this.state.step === 3 ?
                    <Fragment>
                      <h2>Congratulations!</h2>
                      <p> Your BYO was claimed successfully and you are now the owner of <span>{this.state.byo_detail.name}</span> BYO.</p>
                      <div>
                        Go to
                          {" "}<Link to="/profile#byo"><b>My Account</b></Link>{" "}
                        to view your BYO.
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                      <h2>BYO Claim Request Submitted!</h2>
                      <p>Your BYO claim request was submitted successfully and is under review, you can see status in <Link to="/profile#claimed_requests"><b>My Account</b></Link> section</p>
                    </Fragment>

                  }
                </div>
              </Fragment>
            }
          </div>
        </div>
      </Layout>
    );
  }
}
// const renderer = ({ minutes, seconds }) => {
//   return <span>{minutes}m {seconds}s</span>;
// };