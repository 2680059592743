import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import './home.scss';
import Layout from "../../layout";
import SearchResults from "../search/result";
import Cookies from 'js-cookie'
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';
export default class Home extends Component {
  state = {

  }

  componentDidMount() {
    ReactGA.initialize('UA-143205454-1');
     ReactGA.pageview('/');
    // ReactGA.event({
    //   category: "Forgot Password",
    //   action: "click",
    //   label: "Clicked on Forgot Password"
    // })
  }

  render() {
    return (
      <Layout {...this.props}>
        <Helmet>
          <title>BYOFinder | Find, Claim, Manage & Market Your BYOs</title>
          <meta name="description" content="BYOFinder will help you in finding the BYO owned by you based on Post code and Name. You can claim it and Manage the content shown on BYO finder app (iOS & Android)" />
        </Helmet>
        <Fragment>
          <div className="homepage">
            <div className="container">
              <div className="row">
                {Cookies.get('token') ?
                  <Fragment>
                    <div className="col-12 col-md-6">
                      <div className="wrap_welcome">
                        <h2>Hi, <span>{this.props.profile_data.first_name ? this.props.profile_data.first_name : ""} {this.props.profile_data.last_name ? this.props.profile_data.last_name : ""}</span></h2>
                        <div>
                          <Link className="manage_account_link" to="/profile#byo">
                            Manage Account
                                  <img src="/images/next.svg" alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      {/* <div className="wrap_welcome"> */}
                      {/* <h3 style={{visibility:"hidden"}} >Logout</h3> */}
                      <button className="logout_btn" to='/login' onClick={(e) => this.props.signOut(true)}>
                        Logout
                        <img src="/images/logout.svg" alt="" />
                      </button>
                      {/* </div> */}
                    </div>
                  </Fragment>
                  :
                  <Fragment>
                    <div className="col-12 col-md-6">
                      <div className="wrap_welcome">
                        <h2>Welcome to Cellarmasters <div>
                          <span>BYO Finder</span>
                        </div></h2>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 wrap_right">
                      <div className="wrap_welcome">
                        <h3>Manage your BYO's</h3>
                        <Link className="sign_in_btn" to='/login' >Sign In</Link> <span>|</span> <Link to='/signup' className="sign_in_btn">Register</Link>
                      </div>
                    </div>
                  </Fragment>
                }
              </div>
            </div>
          </div>
          <SearchResults {...this.props} />

        </Fragment>
      </Layout>
    );
  }
}
